<template>
  <b-form @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <b-form-group
              :class="{ error: v$.review.name.$errors.length }"
              :label="$t('review.name')"
            >
              <b-form-input v-model="review.name" />
              <div
                class="input-errors"
                v-for="error of v$.review.name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('review.jobTitle')">
              <b-form-input v-model="review.jobTitle" />
            </b-form-group>

            <b-form-group
              :class="{ error: v$.review.description.$errors.length }"
              :label="$t('review.description')"
            >
              <tinymce
                :content="review.description"
                @editorContentChanged="updateContent"
              />
              <div
                class="input-errors"
                v-for="error of v$.review.description.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <b-form-group :label="$t('forms.publish')" label-for="published">
              <b-form-checkbox v-model="review.onOff" name="published" switch />
            </b-form-group>

            <b-form-group
              :class="{ error: v$.review.stars.$errors.length }"
              :label="$t('review.stars')"
            >
              <v-select v-model="review.stars" :clearable="false" :searchable="false" :options="stars" />
              <div
                class="input-errors"
                v-for="error of v$.review.stars.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('review.toProduct')">
              <v-select v-model="review.product" :options="products" />
            </b-form-group>

            <image-upload
              :preview-image="review.fileUrl"
              :label="$t('forms.featuredImage')"
              @fileUploaded="ilustrationImageChange"
              v-if="review['@id']"
              :resource="review['@id']"
              file-attribute="FILE"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";

export default {
  components: {
    vSelect,
    tinymce,
    ImageUpload,
  },
  props: {
    reviewObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      review: {
        name: { required: this.$translateError('required') },
        description: { required: this.$translateError('required') },
        stars: { required: this.$translateError('required') },
      },
    };
  },
  async created() {
    this.getEnumList("review_stars", this.stars);
    await this.getProductsList(this.products);
    if (this.review.product) {
      this.review.product = this.products.find(
        (product) => product.id === this.review.product
      );
    }
  },
  data() {
    return {
      review: this.reviewObject,
      stars: [],
      products: [],
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    ilustrationImageChange(data) {
      this.review.file = data["@id"];
      this.review.fileURL = data.url;
    },
    updateContent(content) {
      this.review.description = content;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.review.name,
          description: this.review.description,
          jobTitle: this.review.jobTitle,
          stars: this.review.stars.id,
          onOff: this.review.onOff,
        };

        if (this.review.product) {
          body.product = this.review.product.id;
        } else {
          body.product = null;
        }

        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Reviews,
                body,
                this.$t("review.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Reviews,
                this.review.id,
                body,
                this.$t("review.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/reviews`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
